<template>
  <div class="container pt-2" data-elbcontext="shopping:discover;component:wishlist">
    <div class="row">
      <h3 id="items" class="col-6 col-lg-8 col-xl-9 font-size-xl c-gray my-3 l-spacing-1 font-weight-bold">
        {{ translate('new_cart_page_wishlist') }}
      </h3>
    </div>
    <div class="row flex-row flex-lg-row-reverse">
      <div class="sidebar col-12 col-lg-4 col-xl-3">
        <div class="row position-sticky">
          <div class="col-12 col-md-8 col-lg-12 pl-0 pr-0 pr-md-3 pr-lg-0"></div>
          <TheCartAwards v-if="showCartAwards" class="col-4 col-lg-12"/>
        </div>
      </div>
      <div class="col-12 col-lg-8 col-xl-9">
        <div class="row" v-if="totalCount < 1 && isUpdating">
          <div class="col-12 px-0 px-md-3">
              <LoadingOverlay class="bg-white border-b mt-2 mt-lg-0 p-3 position-relative" width="140" height="140"/>
          </div>
        </div>
        <div v-else-if="totalCount" class="row">
          <TheWishlistItemsSection v-if="inStockTotalCount" class="col-12 px-0 px-md-3" item-list-id="wishlist"/>
          <TheWishlistSoldOutItemsSection v-if="soldOutTotalCount" class="col-12 px-0 px-md-3" item-list-id="wishlist_sold_out"/>
        </div>
        <div class="mx-md-0" v-else>
          <TheEmptyInfo class="col-12 px-0 px-md-3"
                        icon="icon_svg_heart"
                        headline="empty_wishlist"
                        subline="empty_wishlist_deals"
                        button-title="new_cart_empty_cart_button"
                        :button-link="emptyLink"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'pinia'
import { useWishlistStore } from '@/stores/wishlist.js'

const TheEmptyInfo = () => import('@/components/basic/organisms/TheEmptyInfo.vue')
const TheCartAwards = () => import('@/components/cart/organisms/TheCartAwards')
const LoadingOverlay = () => import('@/components/basic/atoms/LoadingOverlay')
const TheWishlistItemsSection = () => import('@/components/wishlist/organisms/TheWishlistItemsSection')
const TheWishlistSoldOutItemsSection = () => import('@/components/wishlist/organisms/TheWishlistSoldOutItemsSection')

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const { default: SiteConfig } = await import('@/services/SiteConfig')
    cache = { SiteConfig }
    return cache
  }
})()

export default {
  name: 'TheWishlistTemplate',
  components: {
    TheEmptyInfo,
    TheWishlistSoldOutItemsSection,
    TheWishlistItemsSection,
    TheCartAwards,
    LoadingOverlay
  },
  data () {
    return {
      emptyLink: ''
    }
  },
  computed: {
    ...mapState(useWishlistStore, {
      isUpdating: 'updating',
      totalCount: 'totalCount',
      inStockTotalCount: 'inStockTotalCount',
      soldOutTotalCount: 'soldOutTotalCount'
    }),
    offersPath () {
      return this.getVariable('offers_path')
    },
    showCartAwards () {
      return this.$root.gridBreakpoint > this.$gridSizes.md
    }
  },
  watch: {
    async offersPath (relativePath) {
      const { SiteConfig: { pageRoot } } = await _getModules()
      this.emptyLink = `${pageRoot}${relativePath}`
    },
  }
}
</script>
