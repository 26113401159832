<template>
  <div class="my-4 container font-size-regular font-lato" itemprop="contactPoint"
       itemtype="https://schema.org/ContactPoint" itemscope>
    <div class="d-flex flex-column-reverse flex-md-row bg-white py-4 align-items-center">
      <div class="col-12 col-sm-10 col-md-5 pt-5 text-center text-md-left">
        <img class="w-100" :alt="404" src="@/assets/images/404.jpg"/>
      </div>
      <div class="col-12 col-md-7 c-gray d-flex flex-column align-items-center d-md-block text-center text-md-left">
        <h1 class="font-libre font-size-xxxl bold c-graphit">{{ translate('error_page_sorry') }}</h1>
        {{ translate('error_page_not_found') }}
        <div class="d-flex flex-column flex-md-row align-items-center
        justify-content-between justify-content-md-start py-4">
          <CmsBlock identifier="error_page_link_1"/>
          <span class="d-flex align-items-center justify-content-around py-3 px-3">
            {{ translate('fe_app_pdp_or') }}
          </span>
          <CmsBlock identifier="error_page_link_2"/>
        </div>
        <div class="pb-2">{{ translate('error_page_contact_team') }}</div>
        <div class="pt-1">
          {{ translate('fe_app_pdp_phone') }}
          <a class="link-winered" :href="`tel:${servicePhone.link}`">
            {{ servicePhone.text }}
          </a>
        </div>
        <div class="pt-1">
          {{ translate('fe_app_pdp_email') }}
          <a class="link-winered" :href="`mailto:${serviceMail}`">{{ serviceMail }}</a>
        </div>
      </div>
    </div>
    <MultiTools id="fixed-tools" v-once v-if="adminTool && customerIsAdmin" />
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useCustomerStore } from '@/stores/customer'

const CmsBlock = () => import('@/components/basic/atoms/CmsBlock')
const MultiTools = () => import('@/components/basic/molecules/MultiTools')

export default {
  name: 'TheWrapper',
  components: { CmsBlock, MultiTools },
  data () {
    return {
      adminTool: null,
      serviceMail: '',
      servicePhone: {}
    }
  },
  computed: {
    ...mapState(useCustomerStore, ['customerIsAdmin']),
  },
  async created () {
    const [
      { TYPES },
      { default: { phone, email } }
    ] = await Promise.all([
      await import('@/constants/AdminTool'),
      await import('@/services/SiteConfig')
    ])
    this.adminTool = TYPES
    this.servicePhone = phone
    this.serviceMail = email
  }
}
</script>
