<template>
  <div class="flex-grow-1 z-1" id="google-pay-button">
          <google-pay-button
            :environment=environment
            button-type="plain"
            button-color="black"
            button-radius="0"
            button-size-mode="fill"
            style="width: 100%; height: 56px; display:flex;"
            v-bind:existing-payment-method-required="existingPaymentMethodRequired"
            v-bind:paymentRequest.prop="{
              apiVersion: paymentRequest.apiVersion,
              apiVersionMinor: paymentRequest.apiVersionMinor,
              allowedPaymentMethods: paymentRequest.allowedPaymentMethods,
              merchantInfo: paymentRequest.merchantInfo,
              shippingAddressRequired: true,
              emailRequired: true,
              transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: translate('google_pay_total_price_label'),
                totalPrice: String(this.grandTotal),
                currencyCode: this.currencyCode,
                countryCode: this.countryCode,
                displayItems: [
                  {
                    label: translate('google_pay_subtotal_price_label'),
                    type: 'SUBTOTAL',
                    price: String(this.calculated.price.finalPrice),
                  },
                  {
                    label: translate('google_pay_shipping_price_label'),
                    type: 'LINE_ITEM',
                    price: String(this.stock.shipping_costs),
                  },
                ],
              },
            }"
            v-on:loadpaymentdata="onLoadPaymentData"
            v-on:error="onError"
          ></google-pay-button>
  </div>
</template>

<script>
import '@google-pay/button-element'
import braintree from 'braintree-web/client'
import { mapActions, mapState } from 'pinia'
import { useOpcStore } from '@/stores/opc'
import SiteConfig from '@/services/SiteConfig'
import { useCheckoutStore } from '@/stores/checkout'
import { useCustomerStore } from '@/stores/customer'

const _getModules = (() => {
  let cache = null

  return async () => {
    if (!cache) {
      const { _initBraintree } = await import('@/services/Braintree')
      cache = { _initBraintree }
    }
    return cache
  }
})()

export default {
  name: 'BraintreeGooglePayButton',
  props: {},
  computed: {
    ...mapState(useOpcStore, ['calculated', 'grandTotal', 'stock', 'product', 'selectedQty']),
    ...mapState(useCustomerStore, ['customerProfile']),
  },
  data() {
    return {
      existingPaymentMethodRequired: false,
      currencyCode: SiteConfig.currencyConfig.currency,
      countryCode: SiteConfig.locale.toUpperCase(),
      environment: SiteConfig.devMode ? 'TEST' : 'PRODUCTION',
      paymentRequest: {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['AMEX', 'MASTERCARD', 'VISA'],
              billingAddressRequired: true,
              billingAddressParameters: {
                format: 'FULL',
              },
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: 'braintree',
                'braintree:apiVersion': 'v1',
                'braintree:sdkVersion': braintree.VERSION,
                'braintree:merchantId': process.env.VUE_APP_BRAINTREE_MERCHANT_ID,
                'braintree:clientKey': process.env.VUE_APP_BRAINTREE_TOKENIZATION_KEY,
              },
            },
          },
        ],
        merchantInfo: {
          merchantName: SiteConfig.name,
          merchantId: process.env.VUE_APP_GOOGLE_MERCHANT_ID,
        },
      },
    }

  },
  methods: {
    ...mapActions(useCheckoutStore, [
      'updatePaymentData',
      'placeOrder',
      'updateCustomerInfo',
      'replaceBackendError',
      'setExpressCheckout',
      'isInErrorState',
      'setSelectedPaymentMethod',
    ]),
    ...mapActions(useOpcStore, ['setLoading']),
    async onLoadPaymentData(event) {
      this.setLoading(true)
      this.setExpressCheckout(true)

      const extractStreetNumber = (address) => {
        const match = address.match(/(\d+)/)
        return match ? match[0] : ''
      }

      // Helper function to map address fields
      const mapAddressFields = (address) => ({
        first_name: address.name.split(' ')[0],
        last_name: address.name.split(' ')[1],
        gender: null,
        city: address.locality,
        country: address.countryCode.toString().toLowerCase(),
        company: '',
        zip: address.postalCode,
        street_name: address.address1.replace(/\d+/g, '').trim(),
        street_number: extractStreetNumber(address.address1)
      })

      const shippingAddress = event.detail.shippingAddress
      const customerShippingAddress = mapAddressFields(shippingAddress)

      const billingAddress = event.detail.paymentMethodData.info.billingAddress || shippingAddress
      const customerBillingAddress = mapAddressFields(billingAddress)

      if (customerBillingAddress.street_number === '' || customerShippingAddress.street_number === '') {
        this.replaceBackendError({ message: 'street_number_error' })
        this.setLoading(false)
        return
      }

      const tokenData = JSON.parse(event.detail.paymentMethodData.tokenizationData.token)
      const data = tokenData.androidPayCards[0]

      if (!this.customerProfile.email) {
        await this.updateCustomerInfo({ type: 'email', data: { value: event.detail.email } })
      }

      await this.updateCustomerInfo({ type: 'shippingAddress', data: customerShippingAddress })

      const addressesAreSame = JSON.stringify(customerShippingAddress) === JSON.stringify(customerBillingAddress)
      if (!addressesAreSame) {
        await this.updateCustomerInfo({ type: 'billingAddress', data: customerBillingAddress })
        await this.updateCustomerInfo({ type: 'differentBillingAddress', data: { value: true } })
      }

      const { _initBraintree } = await _getModules()
      await _initBraintree()
      await this.setSelectedPaymentMethod('google_pay')
      await this.updatePaymentData({ data })
      await this.placeOrder(this.product, this.selectedQty, this.grandTotal, false)

      if (this.isInErrorState) {
        this.setLoading(false)
      }
    },
    onError: event => {
      console.error('error', event.error)
    },
  },
}
</script>
