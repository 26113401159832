<template>
  <section class="intro-section">
    <div class="d-none d-md-flex justify-content-around intro-container font-size-m py-4 px-5 bg-white box-shadow-default" :class="{'loaded' : !loading}">
      <article class="overflow-hidden pt-2 pb-4 px-3 box-sizing-border-box overflow-y-auto d-flex flex-column">
        <div>
          <div class="hero-image" :loading="loading">
            <img v-if="calculated.heroImage" class="h-auto fit-contain pb-6 w-100" :src="calculated.heroImage" alt="manufacturer introduction"/>
          </div>
          <TextExpander class="manufacturer-maker-description pb-5" :text="manufacturer.maker_description"/>
        </div>
        <button class="btn btn-outline-secondary" @click="scroll">
          {{ translate('more_about_manufacturer') }}
        </button>
      </article>
      <article class="px-3 overflow-auto" :loading="loading">
        <div
          class="logo-row bg-white sticky-top h-auto d-flex flex-row justify-content-between px-3 pb-2 align-items-center">
          <div>
            <img v-if="calculated.logo" class="w-75" :src="calculated.logo" alt="manufacturer logo"/>
          </div>
          <h1 class="w-100 text-center font-libre font-italic font-size-xxxxl">
            {{ name }}
          </h1>
          <div v-if="manufacturer.is_vdp_member === 1" class="vdp-container">
            <img class="vdp-image" :src="calculated.vdpLogo" alt="vdp logo"/>
          </div>
        </div>
        <TheIntroSectionFacts @loaded="$emit('loaded')"/>
      </article>
    </div>
    <div class="d-flex d-md-none flex-column bg-white w-100 h-auto overflow-hidden">
      <article>
        <div class="d-flex flex-column align-items-center">
          <img v-if="calculated.logo" class="w-50" :src="calculated.logo" alt="manufacturer logo"/>
          <div class="d-flex justify-content-around align-items-center py-2 px-4 w-100">
            <h1 class="manufacturer-name text-center font-libre font-italic font-weight-normal font-size-xxxl mb-0">
              {{ name }}
            </h1>
            <div v-if="manufacturer.is_vdp_member === 1" class="vdp-container">
              <img class="vdp-image" :src="calculated.vdpLogo" alt="vdp logo"/>
            </div>
          </div>
        </div>
        <div class="hero-image" :loading="loading">
          <img v-if="calculated.heroImage" class="w-100 h-auto py-4" :src="calculated.heroImage" height="200px"
               alt="manufacturer introduction"/>
        </div>
        <TextExpander class="manufacturer-maker-description pt-2 pb-4 px-4"
                      :text="manufacturer.maker_description" :limit="300"/>
      </article>
      <article
        class="manufacturer-expand justify-content-between align-center flex-grow-1 flex-column overflow-hidden w-100">
        <VExpansionPanels>
          <VExpansionPanel variant="accordion" aria-expanded="false" role="button">
            <VExpansionPanelHeader class="manufacturer-accordion d-flex justify-content-between w-100 font-size-xxl font-weight-light text-uppercase border-light-gray border-top">
              {{ name }}
            </VExpansionPanelHeader>
            <VExpansionPanelContent >
              <TheIntroSectionFacts/>
              <div class="p-2 d-flex justify-content-around d-md-none">
                <button class="btn btn-outline-secondary" @click="scroll">
                  {{ translate('more_about_manufacturer') }}
                </button>
              </div>
            </VExpansionPanelContent>
          </VExpansionPanel>
        </VExpansionPanels>
        <TheReasonWhys class="px-2 pb-5"/>
      </article>
      <div class="p-2 d-none d-md-block">
        <button class="btn btn-outline-secondary" @click="scroll">
          {{ translate('more_about_manufacturer') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'pinia'
import { useManufacturerStore } from '@/stores/manufacturer'
import TheIntroSectionFacts from '@/components/manufacturer/atoms/TheIntroSectionFacts.vue'
import TheReasonWhys from '@/components/manufacturer/atoms/TheReasonWhys.vue'

const TextExpander = () => import('@/components/basic/atoms/TextExpander.vue')

export default {
  name: 'TheIntroSection',
  components: { TheReasonWhys, TextExpander, TheIntroSectionFacts },
  computed: {
    ...mapState(useManufacturerStore, ['manufacturer', 'calculated', 'loading']),
    name() {
      if (!this.manufacturer.name_prefix) {
        return this.manufacturer.name
      }
      return `${this.translate('manufacturer_winery')} ${this.manufacturer.name}`
    },
  },
  methods: {
    async scroll() {
      const { useLazyStore } = await import('@/stores/lazyStore')
      const store = useLazyStore()
      void store.scroll({ selector: '#manufacturer-description-section' })
    }
  }
}
</script>

<style scoped lang="scss">
.hero-image {
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
  margin-bottom: 10px;
}

::v-deep .manufacturer-maker-description {
  font-family: $libre;
  font-size: $font-size-regular;
}

.vdp-container {
  padding: 8px 15px;
  background-color: $beige;
  border-radius: 50%;
}

.vdp-image {
  height: 35px;
}

.logo-row {
  max-height: 25%;
}

.intro-section {
  min-height: auto;
}

@media screen and (min-width: 768px) {
  .intro-section {
    position: relative;
  }
  .intro-container {
    transition: max-height $transition-duration-xxl;
    min-height: 400px;
    max-height: 0;
  }

  .intro-container article {
    flex-basis: 50%;
    flex-grow: 1;
  }

  .intro-container.loaded {
    max-height: 620px;
  }
}

</style>
