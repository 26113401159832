<template>
  <article id="product-stage" class="container d-flex p-0 pt-2 flex-wrap"
           data-elb="product" :data-elb-product="elbData">
    <TheBanners v-if="!loading"/>
    <div class="col-12 col-lg-6 image-wrapper position-relative overflow-hidden">
      <div v-if="!loading" class="black-bg-mobile" :style="bgImageSet"/>
      <picture v-if="!loading" itemprop="image" class="product-image pb-lg-3"
               itemtype="https://schema.org/image"
               :content="smallImageSrcPrefixed"
               :class="{'pt-lg-5': !isBundle}">
        <source media="(min-width: 992px)" :srcset="heroImageSrc">
        <source :srcset="imageSrc">
        <img :src="smallImageSrcPrefixed" alt="product.general.name"/>
      </picture>
      <div class="wishlist-icon">
        <AddToWishlist class="flex-row-reverse f-white c-white" :product="metaProduct"/>
      </div>
      <AwardWrapper v-if="!loading" class="award-list mt-4 pl-3 position-absolute" :awards="product.awards"
                    :threshold="3"/>
    </div>
    <div class="col-12 col-lg-6 py-4 bg-white c-darker font-size-regular d-flex justify-content-center px-lg-0">
      <div class="col-12 col-sm-9 col-lg-10 px-0">
        <div class="d-flex flex-column align-items-center align-items-lg-start">
          <h2 v-if="product.manufacturer_name" itemprop="brand" itemscope="" itemtype="https://schema.org/Brand"
              class="mb-0">
            <VicampoLink id="manufacturer" class="text-uppercase manufacturer-name link-gray" :loading="loading"
               itemprop="url" :href="manufacturerUrl">
              <span itemprop="name">{{ product.manufacturer_name }}</span>
            </VicampoLink>
          </h2>
          <h1 itemprop="name" id="stage-name" class="font-libre font-weight-bolder mt-3 font-size-xl text-center text-lg-left mb-0" :loading="loading">
            {{ product.name }}
          </h1>
        </div>
        <a v-if="!loading && productV1.details.customer_rating"
           class="my-4 d-flex align-items-center justify-content-center justify-content-lg-start"
           href="#customer-reviews">
          <AggregatedRating v-if="!loading" include-props :rating-data="{
            ratingsAverage: productV1.details.customer_rating,
            ratingsCount: productV1.details.customer_rating_count }"
                            :show-dropdown-icon="false"/>
        </a>
        <div itemprop="description" id="reason-why-one" :loading="loading"
             class="text-left mt-2 mb-3">
          <p class="styled-text m-0" v-html="product.reason_why_one"></p>
        </div>
        <Price v-if="!($root.gridBreakpoint < $gridSizes.lg)" :loading="loading" class="py-2"
               :price="calculated.priceObject" :stock="stock" include-props/>
        <MilesAndMoreBanner :earn="milesAndMoreData.earn" :spend="milesAndMoreData.spend"/>
        <DeliveryInfo v-if="stock" class="pt-3" :loading="loading" :stock="stock"/>
        <Cta v-if="!($root.gridBreakpoint < $gridSizes.lg) && !loading" class="pt-2"
             :pre-selected-qty="calculated.preSelectedQty" :product="product"/>
        <Legals class="d-flex flex-column align-items-center align-items-lg-start"/>
      </div>
    </div>
  </article>
</template>

<script>
import Legals from '@/components/pdp/atoms/Legals'
import { mapState } from 'pinia'
import { usePdpStore } from '@/stores/pdp'
import ElbwalkerHelper from '@/services/helpers/ElbwalkerHelper'

const AddToWishlist = () => import('@/components/basic/atoms/AddToWishlist')
const AggregatedRating = () => import('@/components/basic/molecules/AggregatedRating')
const TheBanners = () => import('@/components/pdp/molecules/TheBanners')
const AwardWrapper = () => import('@/components/basic/molecules/AwardWrapper')
const Cta = () => import('@/components/basic/molecules/Cta')
const DeliveryInfo = () => import('@/components/basic/molecules/DeliveryInfo')
const MilesAndMoreBanner = () => import('@/components/basic/molecules/MilesAndMoreBanner')
const Price = () => import('@/components/basic/molecules/Price')
const VicampoLink = () => import( '@/components/basic/atoms/VicampoLink.vue')

export default {
  name: 'ProductStage',
  components: {
    VicampoLink,
    AddToWishlist,
    TheBanners,
    Cta,
    DeliveryInfo,
    MilesAndMoreBanner,
    Price,
    AggregatedRating,
    AwardWrapper,
    Legals
  },
  computed: {
    ...mapState(usePdpStore, {
      product: 'getApiProduct',
      productV1: 'getApiV1Product',
      metaProduct: 'getMetaProduct',
      calculated: 'getCalculated',
      stock: 'getFirstAvailableStock',
      loading: 'isUpdating',
    }),
    milesAndMoreData() {
      return this.metaProduct.milesandmore
    },
    recommendation() {
      return this.metaProduct.recommendation
    },
    smallImageSrcPrefixed() {
      return this.calculated.smallImageSrcPrefixed
    },
    imageSrc() {
      return this.calculated.imageSrc
    },
    heroImageSrc() {
      return this.calculated.heroImageSrc
    },
    bgImageSet() {
      return `backgroundImage: url(${this.$root.gridBreakpoint < this.$gridSizes.md ?
        this.calculated.bgImageSet.X : this.calculated.bgImageSet.XX})`
    },
    manufacturerUrl() {
      return this.product.manufacturer_link
    },
    isBundle() {
      return this.calculated.isBundle
    },
    tags() {
      return this.calculated.tags
    },
    elbData () {
      return ElbwalkerHelper.getProductData(this.product)
    }
  },
  mounted() {
    this.hitLozad()
  },
  watch: {
    async product() {
      await this.$nextTick()
      window.elb('product visible', this.$el)
    }
  },

}
</script>

<style lang="scss" scoped>
#reason-why-one[loading=true] {
  min-height: 5rem;
}

a#manufacturer[loading=true] {
  min-width: 20%;
  min-height: 1.3rem;
}

h1 {
  line-height: $font-size-xxxl;
}

h1[loading=true] {
  min-width: 70%;
  min-height: 1.5rem;
}

.award-list {
  top: 0;
  left: 0;
}

.manufacturer-name {
  font-size: $font-size-m;
  font-family: $lato;
  letter-spacing: 1px;
  font-weight: 700;
}

.product-image {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);

  &[data-loaded=true] {
    opacity: 1;
    transition: $transition-duration-xxxl $transition-timing-ease;
    transition-property: opacity, top;
}
  ::v-deep img {
    height: 100%;
    max-height: 100%;
    object-fit: contain;
    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
      height: auto;
    }
  }
}

.image-wrapper {
  height: 360px;
}

#product-stage {
  min-height: 500px;
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .image-wrapper {
    height: auto;
  }
  #product-stage {
    min-height: auto;
  }
}

.black-bg-mobile {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity $transition-duration-l $transition-timing-ease;
}

::v-deep {
  .banderole-recommendation svg {
    display: none;
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
    .banderole-recommendation svg {
      display: flex;
    }
  }

  .mdc-select {
    min-width: 155px;
  }

  .final-price {
    font-size: $font-size-xxxl;
  }

  .price-per-unit {
    font-size: $font-size-s;
  }
}

.wishlist-icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
</style>
