<template>
  <ul :class="{'facts p-0 overflow-auto font-size-m': $root.gridBreakpoint >= $gridSizes.md,
  'overflow-hidden h-auto px-1 w-100 align-start justify-content-between font-size-m': $root.gridBreakpoint < $gridSizes.md}">
    <template v-for="[key, translation] in Object.entries(config)">
      <li v-if="manufacturer[key]" class="facts-item" :key="key">
        <span class="facts-title">{{ translation }}</span>
        <a v-if="calculated.links[key]" :href="calculated.links[key]" class="link-winered"><span class="facts-value">{{ manufacturer[key] }}</span></a>
        <span v-else class="facts-value">{{ manufacturer[key] }}</span>
      </li>
    </template>
    <li v-if="manufacturer.size !== null" class="facts-item">
      <span class="facts-title">{{ this.translate('manufacturer_size') }}</span>
      <span class="facts-value">{{ manufacturer.size }} {{ this.translate('manufacturer_size_unit') }}</span>
    </li>
    <li v-if="manufacturer.production_amount !== null" class="facts-item">
      <span class="facts-title">{{ this.translate('manufacturer_production_amount') }}</span>
      <span class="facts-value">{{ manufacturer.production_amount }} {{ this.translate('manufacturer_production_amount_unit') }}</span>
    </li>
    <li v-if="manufacturer.best_sites !== null" class="facts-item">
      <span class="facts-title">{{ this.translate('manufacturer_best_sites') }}</span>
      <span class="facts-value">{{ manufacturer.best_sites }}</span>
    </li>
    <li class="d-none d-md-flex facts-item">
      <TheReasonWhys />
    </li>
  </ul>
</template>
<script>
import { mapState } from 'pinia'
import { useManufacturerStore } from '@/stores/manufacturer'
import TheReasonWhys from '@/components/manufacturer/atoms/TheReasonWhys.vue'

export default {
  name: 'TheIntroSectionFacts',
  components: { TheReasonWhys },
  data () {
    return {
      config: {},
      links: {}
    }
  },
  computed: {
    ...mapState(useManufacturerStore, ['manufacturer', 'calculated']),
  },
  async created () {
    this.config = {
      country_name: this.translate('pdp_attribute_country'),
      region_name: this.translate('pdp_attribute_region'),
      established_year: this.translate('manufacturer_established_year'),
      manager: this.translate('manufacturer_manager'),
      cellarmaster: this.translate('manufacturer_cellarmaster'),
      memberships: this.translate('manufacturer_memberships'),
      terroir: this.translate('manufacturer_terroir'),
    }
  },
  async mounted () {
    await this.$nextTick()
    setTimeout(() => { this.$emit('loaded') }, 500)
  }
}

</script>

<style scoped lang="scss">
.facts-title {
  font-weight: 400;
  min-width: 45%;
  color: $gray;
  white-space: nowrap;
  text-align: start;
}
.facts-value {
  max-width: 55%;
  text-align: start;
  white-space: normal;
}

.facts-item {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid lightgrey;
  padding: 18px;
}
</style>
