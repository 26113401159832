import SiteConfig from '@/services/SiteConfig'

const FIELDS = 'id,campaign_id,meta_description,name,url,image_logo'

export const PRODUCTS_PER_PAGE = 30

export const CATEGORY_PAGE_API_URL = {
  SEARCH_BY_CATEGORY_ID: `${SiteConfig.apiRoot}v1/category_search?fields=${FIELDS}&pagination_limit=${PRODUCTS_PER_PAGE}&`, // GET
  SEARCH_BY_IDENTIFIER: `${SiteConfig.apiRoot}v1/category_search?identifier={0}&fields=${FIELDS}&` // GET
}
