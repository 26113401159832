<template>
  <div class="flex-grow-1">
    <VBtn v-show="!paymentInProgress" id="apple-pay-button" />
    <LoadingOverlay v-show="paymentInProgress" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useOpcStore } from '@/stores/opc'
import { useCheckoutStore } from '@/stores/checkout'
import { useCustomerStore } from '@/stores/customer'
const LoadingOverlay = () => import('@/components/basic/atoms/LoadingOverlay')

const _getModules = (() => {
  let cache = null

  return async () => {
    if (!cache) {
      const { useApplePay } = await import('@/services/helpers/ApplePayHelper')
      cache = { useApplePay }
    }
    return cache
  }
})()

export default {
  name: 'BraintreeApplePayButton',
  components: { LoadingOverlay },
  computed: {
    ...mapState(useOpcStore, ['grandTotal', 'product', 'selectedQty']),
    ...mapState(useCheckoutStore, ['paymentInProgress', 'isInErrorState']),
    ...mapState(useCustomerStore, ['customerProfile']),
  },
  methods: {
    ...mapActions(useCheckoutStore, [
      'placeOrder',
      'updatePaymentData',
      'updateCustomerInfo',
      'replaceBackendError',
      'setExpressCheckout',
]),
    ...mapActions(useOpcStore, ['setLoading']),
    isApplePaySupported () {
      // eslint-disable-next-line no-undef
      return window.ApplePaySession && ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments()
    },

    async handleApplePayClick () {
      this.setLoading(true)
    },

    async handleApplePayCancel () {
      this.setLoading(false)
    },

    async handleApplePayApprove (nonce, shippingAddress, billingAddress) {
      this.setExpressCheckout(true)

      const extractStreetNumber = (address) => {
        const match = address.match(/(\d+)/)
        return match ? match[0] : ''
      }
      console.log(shippingAddress)
      const mapAddressFields = (address) => ({
        first_name: address.givenName,
        last_name: address.familyName,
        gender: null,
        city: address.locality,
        country: address.countryCode.toLowerCase(),
        company: '',
        zip: address.postalCode,
        street_name: address.addressLines[0].replace(/\d+/g, '').trim(),
        street_number: extractStreetNumber(address.addressLines[0]),
        additional: address.addressLines[1] === undefined ? null : address.addressLines[1]
      })

      const customerShippingAddress = mapAddressFields(shippingAddress)
      const customerBillingAddress = mapAddressFields(billingAddress)

      if (customerBillingAddress.street_number === '' || customerShippingAddress.street_number === '') {
        this.replaceBackendError({ message: 'street_number_error' })
        this.setLoading(false)
        return
      }

      const data = {
        tokenized_nonce: nonce,
        method_code: 'apple_pay',
        is_default_payment_method: false
      }

      if (!this.customerProfile.email) {
        await this.updateCustomerInfo({ type: 'email', data: { value: shippingAddress.emailAddress } })
      }

      await this.updatePaymentData({ data })
      await this.updateCustomerInfo({ type: 'shippingAddress', data:  customerShippingAddress })

      const addressesAreSame = JSON.stringify(customerShippingAddress) === JSON.stringify(customerBillingAddress)
      if (!addressesAreSame) {
        await this.updateCustomerInfo({ type: 'billingAddress', data: customerBillingAddress })
        await this.updateCustomerInfo({ type: 'differentBillingAddress', data: { value: true } })
      }

      await this.placeOrder(this.product, this.selectedQty, this.grandTotal, false)
      this.setLoading(false)
    },

    handleApplePayError () {
      this.updatePaymentData({ error: true })
      this.setLoading(false)
    },

    async initializeApplePay () {
      const handlers = {
        onApprove: this.handleApplePayApprove,
        onError: this.handleApplePayError,
        onClick: this.handleApplePayClick,
        onCancel: this.handleApplePayCancel,
        dataCollector: this.handleDataCollector,
        getGrandTotal: this.getGrandTotal
      }

      try {
        const { useApplePay } = await _getModules()
        await useApplePay(handlers)
      } catch (error) {
        console.error('Failed to load Apple Pay module', error)
        this.removeApplePayButton()
      }
    },

    removeApplePayButton () {
      const applePayContainer = document.getElementById('apple-pay-container')
      if (applePayContainer) {
        applePayContainer.remove()
        const googlePayButton = document.getElementById('google-pay-button')
        if (!googlePayButton) {
          const expressCheckouts = document.getElementById('express-checkouts')
          expressCheckouts.remove()
        }
      }
    },

    handleDataCollector (data) {
      this.deviceData = JSON.parse(data)
    },

    getGrandTotal () {
      return this.grandTotal
    }
  },

  async mounted () {
    await this.$nextTick()

    if (this.isApplePaySupported()) {
      await this.initializeApplePay()
    } else {
      this.removeApplePayButton()
    }
  },
}
</script>

<style lang="scss" scoped>
#apple-pay-button {
  border-radius: 0;
  display: flex;
  width: 100%;
  height: 56px;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
  appearance: -apple-pay-button;
}
</style>
