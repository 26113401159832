<template>
  <div class="position-relative">
    <transition name="fade">
      <div v-if="mainContent" v-show="pageLoaded">
        <Processor :template="mainContent" :identifier="processorId" @rendered="rendered"/>
      </div>
    </transition>
    <LoadingOverlay v-show="!pageLoaded" class="position-relative my-5" :use-background="false"/>
    <MultiTools id="fixed-tools" v-if="customerIsAdmin">
      <Tool :type="adminTool.EDIT_CMS" :id="page.page_id"/>
    </MultiTools>
  </div>
</template>

<script>
import { TYPES } from '@/constants/AdminTool'
import promotionContentMixin from '@/components/basic/mixins/promotionContentMixin'
import cssMixin from '@/components/basic/mixins/cssMixin'
import jsMixin from '@/components/basic/mixins/jsMixin'
import { mapState } from 'pinia'
import { useCustomerStore } from '@/stores/customer'
import { useCmsPagesStore } from '@/stores/cmsPages'

const Processor = () => import('@/components/cms/Processor')
const LoadingOverlay = () => import('@/components/basic/atoms/LoadingOverlay')
const MultiTools = () => import('@/components/basic/molecules/MultiTools')
const Tool = () => import('@/components/basic/atoms/Tool')

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { elb },
      { WALKER_COMMANDS }
    ] = await Promise.all([
      await import('@elbwalker/walker.js'),
      await import('@/services/helpers/ElbwalkerHelper')
    ])
    cache = { elb, WALKER_COMMANDS }
    return cache
  }
})()

export default {
  name: 'TheCmsTemplate',
  components: {
    LoadingOverlay,
    Processor,
    Tool,
    MultiTools
  },
  mixins: [promotionContentMixin, cssMixin, jsMixin],
  props: {
    pageId: [String, null]
  },
  data () {
    return {
      pageLoaded: false,
      adminTool: TYPES
    }
  },
  computed: {
    ...mapState(useCustomerStore, ['customerIsAdmin']),
    ...mapState(useCmsPagesStore, ['page', 'mainContent', 'mainContentCss', 'mainContentJs']),
    processorId () {
      return `page-${this.pageId}`
    }
  },
  methods: {
    setupPage () {
      this.css = this.mainContentCss
      this.js = this.mainContentJs
      this.trackCmsPageView()
    },
    async rendered () {
      this.pageLoaded = true
      await this.hitLozad()
      setTimeout(() => { this.initPromotionAndContentTracking() }, 0)
    },
    async trackCmsPageView () {
      const { default: TrackingHelper } = await import('@/services/helpers/TrackingHelper')
      TrackingHelper.sendCmsPageView(this.page.page_id)
    }
  },
  created () {
    this.setupPage()
  },
  async mounted() {
    await this.$nextTick()
    setTimeout(async () => {
      const { elb, WALKER_COMMANDS } = await _getModules()
      elb(WALKER_COMMANDS.init, this.$el)
    }, 100)
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 1s $transition-timing-cb-vicampo .7s
}

.fade-leave-active {
  transition: opacity .5s $transition-timing-cb-vicampo
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
