<template>
  <div class="bg-white c-gray f-gray sticky-top" id="opc-header">
    <div class="container d-flex justify-content-between align-items-center l-spacing-half pb-3 pt-1 pt-sm-3 px-0 px-sm-3">
      <div  class="col-6 col-md-4 col-lg-3">
        <VicampoLink id="opc-logo" href="/" itemscope itemtype="https://schema.org/Brand">
          <img src="@/assets/images/logo-main.svg" class="w-100 h-100" alt="vicampo">
        </VicampoLink>
      </div>
      <div class="trust d-none d-lg-flex col-6 col-md-4 col-lg-3">
        <SvgWrapper use="icon_svg_money_return" dimensions="40"/>
        <div class="pl-1">
          <div class="font-size-s font-weight-bold text-uppercase">{{ translate('taste_guarantee') }}</div>
          <div class="font-size-xs font-libre font-italic">{{ translate('refund_subline') }}</div>
        </div>
      </div>
      <div v-if="freeShipping" class="trust d-flex col-6 col-md-4 col-lg-3 pl-0 pl-sm-3 d-flex align-items-center">
        <SvgWrapper use="icon_svg_delivery_free" dimensions="40" class="flex-shrink-0"/>
        <div>
          <div class="font-size-s font-weight-bold ml-3 text-uppercase">{{ translate('free_shipping') }}</div>
          <div class="font-size-xs font-libre font-italic d-none d-sm-block ml-3">{{ translate('shipping_subline_opc') }}</div>
        </div>
      </div>
      <div class="trust d-none d-md-flex col-6 col-md-4 col-lg-3">
        <SvgWrapper use="icon_svg_phone" dimensions="40"/>
        <div class="d-flex flex-column">
          <div id="hotline" class="font-size-s font-weight-bold">
            {{ phoneNumber }}
          </div>
          <div class="font-size-xs font-libre font-italic">{{ translate('order_on_phone') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useOpcStore } from '@/stores/opc'

const SvgWrapper = () => import('@/components/basic/atoms/SvgWrapper')
const VicampoLink = () => import('@/components/basic/atoms/VicampoLink')

const _getModules =(() => {
  let cache

  return async () => {
    if (cache) return cache

    const { default: { phone: { text: phoneNumber } } } = await import('@/services/SiteConfig')

    cache = { phoneNumber }
    return cache
  }
})()


export default {
  name: 'TheOpcHeader',
  components: { SvgWrapper, VicampoLink },
  data: () => ({ phoneNumber: '' }),
  computed: {
    ...mapState(useOpcStore, ['stock']),
    freeShipping () {
      return !this.stock.shipping_costs
    }
  },
  async created () {
    const { phoneNumber } = await _getModules()
    this.phoneNumber = phoneNumber
  }
}
</script>

<style lang="scss" scoped>
#opc-header {
  height: 55px;
}
#opc-logo {
  width: 160px;
}
.trust {
  align-items: start;
  min-width: 160px;
}
.sticky-top {
  z-index: 4;
}
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  #opc-header {
    height: 75px;
  }
  .trust {
    min-width: 240px;
  }
  #hotline {
    height: 18px;
  }
  #opc-logo {
    min-width: 240px;
  }
}
</style>
