<template>
<div class="reason-why c-gray f-gray text-left">
  <div v-if="rwOne">
    <SvgWrapper use="icon_svg_heartglass_filled" dimensions="20" class="p-1"/><span>{{ rwOne }}</span>
  </div>
  <div v-if="rwTwo">
    <SvgWrapper use="icon_svg_heartglass_filled" dimensions="20" class="p-1"/><span>{{ rwTwo }}</span>
  </div>
  <div v-if="rwThree">
    <SvgWrapper use="icon_svg_heartglass_filled" dimensions="20" class="p-1"/><span>{{ rwThree  }}</span>
  </div>
</div>
</template>
<script>
import { mapState } from 'pinia'
import { useManufacturerStore } from '@/stores/manufacturer'
const SvgWrapper = () => import('@/components/basic/atoms/SvgWrapper.vue')

export default {
  name: 'TheReasonWhys',
  components: { SvgWrapper },
  computed: {
    ...mapState(useManufacturerStore, ['manufacturer']),
    rwOne() {
      return this.manufacturer.reason_why_one?.trim()
    },
    rwTwo() {
      return this.manufacturer.reason_why_two?.trim()
    },
    rwThree () {
      return this.manufacturer.reason_why_three?.trim()
    }
  }
}

</script>

<style scoped lang="scss">
.reason-why > li :first-child {
  width: 20px;
}
</style>
