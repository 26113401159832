<template>
  <div class="position-relative">
    <LoadingOverlay v-if="loading"/>
    <TheOpcHeader/>
    <main id="page-template" :class="{ 'pointer-events-none' : paymentInProgress }">
      <TheOpcStage data-elblink="product:child"/>
      <TheOpcErrorMessage/>
      <TheOpcBody data-elblink="product:parent"/>
      <LazyComponent id="opc-footer" :delay="1500">
        <TheOpcFooter/>
      </LazyComponent>
    </main>
    <MultiTools id="fixed-tools" v-if="customerIsAdmin && productLoaded">
      <Tool :type="adminTool.EDIT_PRODUCT" :id="product.product_id" :campaign-id="product.campaign_id"/>
    </MultiTools>
    <TheOpcSoldOutOverlay v-if="soldOut" :show-hide-button="customerIsAdmin" @hide="soldOut=false"/>
  </div>
</template>

<script>
import TheOpcStage from '@/components/opc/molecules/TheOpcStage'
import TheOpcHeader from '@/components/opc/organisms/TheOpcHeader'

import { useOpcStore } from '@/stores/opc'
import { mapState } from 'pinia'
import { useCustomerStore } from '@/stores/customer'
import { TYPES } from '@/constants/AdminTool'
import { useCheckoutStore } from '@/stores/checkout'

const LoadingOverlay = () => import('@/components/basic/atoms/LoadingOverlay.vue')
const LazyComponent = () => import('@/components/basic/atoms/LazyComponent.vue')
const MultiTools = () => import('@/components/basic/molecules/MultiTools.vue')
const Tool = () => import('@/components/basic/atoms/Tool.vue')
const TheOpcBody = () => import('@/components/opc/organisms/TheOpcBody.vue')
const TheOpcFooter = () => import('@/components/opc/organisms/TheOpcFooter.vue')
const TheOpcErrorMessage = () => import('@/components/opc/atoms/TheOpcErrorMessage.vue')
const TheOpcSoldOutOverlay = () => import('@/components/opc/organisms/TheOpcSoldOutOverlay.vue')

export default {
  name: 'TheOpcTemplate',
  components: {
    LoadingOverlay,
    TheOpcSoldOutOverlay,
    TheOpcErrorMessage,
    Tool,
    MultiTools,
    TheOpcStage,
    TheOpcHeader,
    LazyComponent,
    TheOpcBody,
    TheOpcFooter
  },
  data () {
    return {
      adminTool: TYPES,
      soldOut: false
    }
  },
  computed: {
    ...mapState(useOpcStore, ['product', 'loading']),
    ...mapState(useCheckoutStore, ['paymentInProgress']),
    ...mapState(useCustomerStore, ['customerIsAdmin']),
    productLoaded () {
      return this.product.product_id && this.product.campaign_id
    }
  },
  watch: {
    productLoaded () {
      this.soldOut = !this.product.stocks.some(({ is_in_stock: isInStock }) => isInStock)
    },
    soldOut (isSoldOut) {
      document.body.style.overflow = isSoldOut ? 'hidden' : null
      document.body.style.height = isSoldOut ? '100vh' : null
    }
  }
}
</script>
