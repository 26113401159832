import SiteConfig from '@/services/SiteConfig'

export const TYPES = {
  EDIT_PRODUCT: 'edit-product',
  EDIT_CATEGORY: 'edit-category',
  EDIT_CMS: 'edit-cms',
  EDIT_MANUFACTURER: 'edit-manufacturer',
  EDIT_REVIEW: 'edit-review',
  VIEW_CUSTOMER: 'view-customer',
  TRANSLATE_MODE: 'translate-mode',
  RELATED_PRODUCTS: 'related-products',
  HIGHLIGHT_CMS_ITEMS: 'highlight-cms-items',
  EDIT_CMS_BLOCK: 'edit-cms-block',
  TOGGLE_SOLR_DATA: 'toggle-solr-data',
  TOGGLE_DATALAYER_LOGGING: 'toggle-datalayer-logging',
  TOGGLE_ELBWALKER_LOGGING: 'toggle-elbwalker-logging'
}

export const URLS = {
  EDIT_PRODUCT: SiteConfig.pageRoot + 'admin/catalog/admin_products/edit/scope_id/{0}/id/{1}/campaign_id/{2}', // 0: site_id 1: product_id 2: campaign_id
  EDIT_CATEGORY: SiteConfig.pageRoot + 'admin/catalog/admin_categories/edit/id/{0}', // 0: category_id
  EDIT_CMS: SiteConfig.pageRoot + 'admin/cms/admin_pages/edit/id/{0}', // 0: cms_id
  EDIT_MANUFACTURER: SiteConfig.pageRoot + 'admin/catalog/admin_manufacturers/edit/scope_id/{0}/id/{1}', // 0: site_id 1:manufacturer_id
  VIEW_CUSTOMER: SiteConfig.pageRoot + 'admin/customer/admin_customers/view/hashid/{0}', // 0: hash_id
  EDIT_REVIEW: SiteConfig.pageRoot + 'admin/review/admin_reviews/edit/hashid/{0}', // 0: hash_id
  CROSS_SELLS: SiteConfig.pageRoot + 'catalog/cross-sells/{0}',
  EDIT_CMS_BLOCK: SiteConfig.pageRoot + 'admin/cms/admin_blocks/edit/id/{0}'
}
