<template>
  <div class="position-relative">
    <ProductStage data-elblink="product:parent"/>
    <RateProduct v-if="hasBeenBought && productLoaded" :product-id="product.product_id"
                 :date="lastPurchaseDate"
                 :rating="rating"/>
    <MainFacts v-if="productLoaded && !unitTypePiece && (productIsSimple || singleBundle)"/>
    <TheCrossSells v-if="productIsSimple" :key="'cross-sells-'+product.product_id"/>

    <LazyComponent id="details">
      <Details data-elblink="product:child"/>
    </LazyComponent>

    <LazyComponent id="recent-items" v-if="customerSoftLoggedIn" :delay="1000">
      <TheRecentlyViewedItems :key="'recent-items-'+product.product_id" class="border-winered pt-5"/>
    </LazyComponent>

    <MultiTools id="fixed-tools" v-if="customerIsAdmin && productLoaded">
      <Tool :type="adminTool.EDIT_PRODUCT" :id="product.product_id" :campaign-id="product.campaign_id"/>
      <Tool :type="adminTool.RELATED_PRODUCTS" :id="product.product_id"/>
    </MultiTools>
    <ThePdpAddToCartMobile v-if="product.product_id" class="d-block d-lg-none" data-elblink="product:child"/>
  </div>
</template>

<script>
import ProductStage from '@/components/pdp/organisms/ProductStage'
import { PRODUCT_TYPES } from '@/constants/GlobalConstants'
import { TYPES } from '@/constants/AdminTool'
import ElbwalkerHelper from '@/services/helpers/ElbwalkerHelper'
import { mapState } from 'pinia'
import { useCustomerStore } from '@/stores/customer'
import { usePdpStore } from '@/stores/pdp'

const LazyComponent = () => import('@/components/basic/atoms/LazyComponent.vue')
const ThePdpAddToCartMobile = () => import('@/components/pdp/atoms/ThePdpAddToCartMobile')
const MainFacts = () => import('@/components/pdp/molecules/MainFacts')
const RateProduct = () => import('@/components/pdp/molecules/RateProduct')
const MultiTools = () => import('@/components/basic/molecules/MultiTools')
const Tool = () => import('@/components/basic/atoms/Tool')
const Details = () => import('@/components/pdp/organisms/Details')
const TheRecentlyViewedItems = () => import('@/components/basic/molecules/TheRecentlyViewedItems')
const TheCrossSells = () => import('@/components/pdp/molecules/TheCrossSells.vue')

export default {
  name: 'ProductDetails',
  components: {
    LazyComponent,
    RateProduct,
    Tool,
    MultiTools,
    MainFacts,
    ProductStage,
    TheCrossSells,
    ThePdpAddToCartMobile,
    TheRecentlyViewedItems,
    Details
  },
  data () {
    return {
      adminTool: TYPES
    }
  },
  computed: {
    ...mapState(useCustomerStore, ['customerProfile', 'customerIsAdmin', 'customerSoftLoggedIn']),
    ...mapState(usePdpStore, {
      product: 'getApiProduct',
      metaProduct: 'getMetaProduct',
    }),
    productIsSimple () { return this.product.type === PRODUCT_TYPES.SIMPLE },
    unitTypePiece () { return this.product.unit_type === 'piece' },
    singleBundle () { return this.product.bundle_items.length === 1 },
    hasBeenBought () { return this.metaProduct.has_been_bought },
    lastPurchaseDate () { return this.metaProduct.last_purchase_date },
    rating () { return this.metaProduct.rating },
    productLoaded () { return this.product.product_id && this.product.campaign_id },
    elbContext () {
      return ElbwalkerHelper.getProductData(this.product)
    }
  }
}
</script>
