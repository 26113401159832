<template>
  <div>
    <div v-if="$root.gridBreakpoint < $gridSizes.sm" class="mobile-background"></div>
    <div class="package-claim d-flex justify-content-center align-items-center">
      <span v-show="!updating" class="font-libre font-size-xxl">{{ translate('opc_package_info', packageQty) }}</span>
    </div>
  </div>
</template>
<script>
import { useOpcStore } from '@/stores/opc'
import { mapState } from 'pinia'

export default {
  name: 'PackageClaim',
  computed: {
    ...mapState(useOpcStore, ['calculated', 'updating']),
    packageQty () {
      return this.calculated.packageQty
    },
  }
}
</script>
<style lang="scss" scoped>
.mobile-background {
  height: 17px;
  background-color: $white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.package-claim {
  height: 45px;
  position: absolute;
  top: -17px;
  width: 90%;
  left: 0;
  right: 0;
  margin-inline: auto;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 280px auto;
  background-image: url("@/assets/images/opc/bg_claim_flag_mobile.png");

  @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
    top: -22px;
    background-size: 316px auto;
    background-image: url("@/assets/images/opc/bg_claim_flag_desktop.png");
  }
}
</style>
