<template>
  <div  :class="{'flex-grow-1 w-100 pb-2 pb-md-4': isExpressCheckout}">
    <div v-if="isExpressCheckout">
              <VSelect 
                v-model="qty" 
                :items="quantitySelectList" 
                :label="translate('tile_quantity_selector_label')"
                outlined
                hide-details
                @change="updateQty(qty)" 
              />
    </div>
    <div v-if="!isExpressCheckout">
      <div class="d-flex flex-column">
        <div class="product-headline d-block d-lg-none text-uppercase mb-3 pt-3">
          <h2 class="mb-3 font-lato font-size-s l-spacing-1 font-weight-bold text-uppercase c-gray">
            {{ translate('opc_amount') }}
          </h2>
        </div>
        <div class="row">
          <div class="d-flex col-6 col-sm-4">
            <VSelect v-model="qty" :items="quantitySelectList" :label="translate('tile_quantity_selector_label')"
                    outlined @change="updateQty(qty)" />
          </div>
          <StockAvailability class="col-6 col-md-8 text-right pr-0" v-if="params.preset_stock_availability"
                            :percentage="params.preset_stock_availability"/> 
          </div>
          <h2 class="mt-3 font-size-xxxl">
            {{ translate('opc_package_selection') }}<span :loading="updating" class="ml-2">{{ totalSavings | currency }}.</span>
          </h2>
          <small :loading="updating">
            {{ translate('opc_package_contains', product.shipping_qty) }} |
            <span v-show="referenceUnit">{{ product.reference_unit_contents | float }} {{ referenceUnit }}</span>
          </small>
        </div>
    </div>
  </div>
</template>

<script>
import { useOpcStore } from '@/stores/opc'
import { mapActions, mapState } from 'pinia'

const StockAvailability = () => import('@/components/basic/atoms/StockAvailability')

export default {
  name: 'TheOpcQuantitySelector',
  components: { StockAvailability },
  props: {
    isExpressCheckout: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      qty: this.selectedQty ?? 1
    }
  },
  computed: {
    ...mapState(useOpcStore, ['product', 'params', 'calculated', 'updating','selectedQty']),
    totalSavings () {
      return this.calculated.price.totalSavings
    },
    quantitySelectList () {
      return this.calculated.quantitySelectList
    },
    referenceUnit () {
      return this.product.reference_unit?.toUpperCase() ?? ''
    }
  },
  watch: {
    selectedQty(newQty) {
      this.qty = newQty
    }
  },
  methods: {
    ...mapActions(useOpcStore, ['updateSelectedQty']),
    updateQty(qty) {
      this.updateSelectedQty(qty)
    }
  }
}
</script>
